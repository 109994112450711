import PropTypes from 'prop-types';
import React from 'react';
import { motion } from 'framer-motion';
import { useToast } from '../hooks/useToast';
import { copyToClipboard } from '../utils';
import { socialLinksWithIcon as socialLinks } from '../constants';

const BlogSharing = ({ canonicalUrl }) => {
  const { addToast } = useToast();

  const handleCopyLink = async () => {
    copyToClipboard(canonicalUrl, addToast);
  };

  return (
    <div className="flex gap-4 mx-2 md:my-6">
      {socialLinks.map((link) => (
        <a
          key={link.url}
          href={link.url + canonicalUrl}
          target="_blank"
          rel="noreferrer"
        >
          <motion.div className="w-8" whileHover={{ scale: 1.1 }}>
            <img
              className="w-full"
              src={`/icons/${link.icon}.svg`}
              alt="Logo"
            />
          </motion.div>
        </a>
      ))}
      <motion.button
        onClick={handleCopyLink}
        className="w-8"
        whileHover={{ scale: 1.1 }}
        aria-label="Copy link to clipboard"
      >
        <img
          className="w-full"
          src="/icons/share-link-color-icon.svg"
          alt="Copy link"
        />
      </motion.button>
    </div>
  );
};

BlogSharing.propTypes = {
  canonicalUrl: PropTypes.string.isRequired,
};

export default BlogSharing;
